export default [
  // {
  //   title: 'Home',
  //   route: 'home',
  //   icon: 'HomeIcon',
  // },
  {
    title: 'Dashboard',
    route: 'dashboard',
    icon: 'HomeIcon',
    resource: 'acl',
    action: 'read',
  },
  // {
  //   title: 'Detail',
  //   route: 'detail',
  //   icon: 'FileIcon',
  // },
  {
    title: 'Projects',
    route: 'projects',
    icon: 'BriefcaseIcon',
    resource: 'acl',
    action: 'read',
  },
  // {
  //   title: 'Second Page',
  //   route: 'second-page',
  //   icon: 'FileIcon',
  // },
  {
    title: 'User',
    route: 'user-management',
    icon: 'UserIcon',
    resource: 'user-management',
    action: 'read',
  },
  {
    title: 'Setting',
    icon: 'SettingsIcon',
    resource: 'acl',
    action: 'read',
    children: [
      {
        title: 'Business Type',
        route: 'business-type',
        resource: 'acl',
        action: 'read',
      },
      {
        title: 'Hashtags',
        route: 'hashtags',
        resource: 'acl',
        action: 'read',
      },
    ],
  },
]
